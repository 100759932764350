import { Box, Typography, useTheme } from "@mui/material";
import { shades } from "../../theme";

const Contact = () => {
  const theme = useTheme();

  const hours = [
    { day: "Monday", time: "10 AM–6 PM" },
    { day: "Tuesday", time: "10 AM–6 PM" },
    { day: "Wednesday", time: "10 AM–6 PM" },
    { day: "Thursday (Thanksgiving)", time: "Closed" },
    { day: "Friday (Black Friday)", time: "10 AM–6 PM" },
    { day: "Saturday", time: "Closed" },
    { day: "Sunday", time: "Closed" },
  ];

  return (
    <Box
      width="80%"
      margin="80px auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography variant="h1" textAlign="center" marginBottom="40px">
        Customer Support Hours
      </Typography>

      {/* Contact Information */}
      <Box marginBottom="40px" textAlign="center">
        <Typography variant="h3" marginBottom="20px">
          Contact Information
        </Typography>
        <Typography variant="body1" marginBottom="10px">
          Email:{" "}
          <a
            href="mailto:info@roadrace1.com"
            style={{ color: shades.secondary[500], textDecoration: "none" }}
          >
            info@roadrace1.com
          </a>
        </Typography>
        <Typography variant="body1" marginBottom="10px">
          Call:{" "}
          <a
            href="tel:843-471-0353"
            style={{ color: shades.secondary[500], textDecoration: "none" }}
          >
            (843) 471-0353
          </a>
        </Typography>
        <Typography variant="body1" marginBottom="10px">
          Text (fastest response time):{" "}
          <a
            href="sms:843-471-0353"
            style={{ color: shades.secondary[500], textDecoration: "none" }}
          >
            (843) 471-0353
          </a>
        </Typography>
      </Box>

      {/* Hours */}
      <Box
        width="100%"
        maxWidth="600px"
        sx={{
          "& > :nth-of-type(odd)": {
            backgroundColor: shades.neutral[100],
          },
        }}
      >
        {hours.map((item, i) => (
          <Box
            key={i}
            display="flex"
            justifyContent="space-between"
            padding="15px 20px"
          >
            <Typography>{item.day}</Typography>
            <Typography>{item.time}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Contact;
