import { useState } from "react";
import { Box, Typography, useTheme, Button } from "@mui/material";
import { shades } from "../theme";
import { useNavigate } from "react-router-dom";

const Item = ({ item, width }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();
  const {
    palette: { neutral },
  } = theme;

  if (!item) {
    return null;
  }

  const { productType, variants, title, mainImage } = item;
  const price = variants[0]?.price || "Contact for Price";

  return (
    <Box 
      width={width}
      sx={{
        backgroundColor: '#fff',
        borderRadius: '8px',
        border: `1px solid ${theme.palette.divider}`,
        boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
        transition: 'box-shadow 0.2s ease-in-out',
        height: '100%',
        '&:hover': {
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        }
      }}
    >
      <Box
        position="relative"
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        sx={{ p: 2 }}
      >
        <img
          alt={title}
          width="300px"
          height="300px"
          src={mainImage}
          onClick={() => navigate(`/product/${item.handle}`)}
          style={{ 
            cursor: "pointer",
            objectFit: "contain",
            width: "100%",
            height: "200px",
            display: "block",
            margin: "0 auto"
          }}
        />
      </Box>
      <Box p={2} pt={1}>
        <Typography 
          variant="subtitle2" 
          color={neutral.dark}
          sx={{
            fontSize: '0.75rem',
            textTransform: 'uppercase',
            letterSpacing: '0.5px',
            mb: 0.5
          }}
        >
          {productType}
        </Typography>
        <Typography
          sx={{
            fontSize: '0.9rem',
            lineHeight: 1.3,
            height: '2.6em',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            mb: 1
          }}
        >
          {title}
        </Typography>
        <Typography 
          fontWeight="bold"
          sx={{
            fontSize: '1rem',
            color: theme.palette.text.primary,
            mb: 2
          }}
        >
          {typeof price === 'string' ? price : `$${parseFloat(price).toFixed(2)}`}
        </Typography>
        <Button
          fullWidth
          variant="contained"
          onClick={() => navigate(`/product/${item.handle}`)}
          sx={{ 
            backgroundColor: theme.palette.secondary.main,
            color: "white",
            py: 1,
            '&:hover': {
              backgroundColor: shades.secondary[600]
            }
          }}
        >
          View Details
        </Button>
      </Box>
    </Box>
  );
};

export default Item;
