import { createTheme } from "@mui/material/styles";

export const shades = {
  primary: {
    100: "#fde4d6",
    200: "#fbc9ad",
    300: "#f9ad85",
    400: "#f7925c",
    500: "#f16b29", // Main orange color
    600: "#c15621", // Darker orange for hover
    700: "#914019",
    800: "#602b10",
    900: "#301508",
  },
  secondary: {
    100: "#cce0ff",
    200: "#99c2ff",
    300: "#66a3ff",
    400: "#3385ff",
    500: "#0b6cff", // Main blue color
    600: "#0851bf", // Darker blue for hover
    700: "#063c8f",
    800: "#042860",
    900: "#021430",
  },
  neutral: {
    100: "#f5f5f5",
    200: "#ecebeb",
    300: "#e2e1e1",
    400: "#d9d7d7",
    500: "#cfcdcd",
    600: "#a6a4a4",
    700: "#7c7b7b",
    800: "#535252",
    900: "#292929",
  },
};

export const theme = createTheme({
  palette: {
    primary: {
      main: shades.primary[500],
    },
    secondary: {
      main: shades.secondary[500],
    },
    neutral: {
      dark: shades.neutral[700],
      main: shades.neutral[500],
      light: shades.neutral[100],
    },
  },
  typography: {
    fontFamily: ["Arial", "sans-serif"].join(","),
    fontSize: 11,
    h1: {
      fontFamily: ["Arial", "sans-serif"].join(","),
      fontWeight: 700,
      fontSize: 48,
      color: shades.secondary[500],
    },
    h2: {
      fontFamily: ["Arial", "sans-serif"].join(","),
      fontWeight: 700,
      fontSize: 36,
      color: shades.secondary[500],
    },
    h3: {
      fontFamily: ["Arial", "sans-serif"].join(","),
      fontWeight: 700,
      fontSize: 20,
      color: shades.secondary[500],
    },
    h4: {
      fontFamily: ["Arial", "sans-serif"].join(","),
      fontWeight: 700,
      fontSize: 14,
      color: shades.secondary[500],
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: shades.secondary[500],
          '&:hover': {
            backgroundColor: shades.secondary[600],
          },
        },
      },
    },
  },
});
