import { useState, useEffect } from "react";
import { 
  Box, 
  Typography, 
  Grid, 
  Container, 
  useTheme, 
  Fade, 
  Breadcrumbs, 
  Link,
  Select,
  MenuItem,
  FormControl
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { shades } from "../../theme";
import Item from "../../components/Item";

function Products() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch("/data/products.json");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setProducts(data);
        // Extract unique categories from product types and sort them
        const uniqueCategories = [...new Set(data.map((item) => item.productType))]
          .filter(Boolean)
          .sort();
        setCategories(uniqueCategories);
        setLoading(false);
      } catch (error) {
        console.error("Error loading products:", error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const filteredProducts = selectedCategory === "all"
    ? products
    : products.filter((item) => item.productType === selectedCategory);

  return (
    <Container maxWidth="xl" sx={{ mt: { xs: 8, sm: 10 }, mb: 5 }}>
      <Breadcrumbs 
        separator={<NavigateNextIcon fontSize="small" />} 
        sx={{ 
          mb: 3,
          '& .MuiBreadcrumbs-separator': {
            mx: 1
          },
          '& .MuiLink-root': {
            color: theme.palette.text.secondary,
            '&:hover': {
              color: theme.palette.secondary.main,
            }
          }
        }}
      >
        <Link 
          component={RouterLink}
          to="/"
          underline="hover"
          sx={{ 
            display: 'flex', 
            alignItems: 'center',
            fontSize: '0.875rem'
          }}
        >
          Home
        </Link>
        <Typography 
          color="text.primary"
          sx={{ fontSize: '0.875rem' }}
        >
          Products
        </Typography>
      </Breadcrumbs>
      <Box 
        sx={{ 
          mb: { xs: 4, sm: 6 },
          textAlign: "left",
          p: { xs: 3, sm: 4 },
          backgroundColor: shades.neutral[100],
          borderRadius: "8px",
          border: `1px solid ${theme.palette.divider}`,
          boxShadow: "0 2px 4px rgba(0,0,0,0.05)"
        }}
      >
        <Typography 
          variant="h3" 
          sx={{
            mb: 2,
            color: theme.palette.text.primary,
            fontWeight: 'bold',
            fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' },
          }}
        >
          Performance Parts & Racing Solutions
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.text.secondary,
            fontSize: { xs: '0.9rem', sm: '1rem' },
            lineHeight: 1.6,
            mb: 1
          }}
        >
          Explore our selection of high-performance parts designed for serious racers and enthusiasts. From precision-engineered brake systems to advanced cooling solutions, each product is designed to deliver exceptional performance on and off the track.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.text.secondary,
            fontSize: { xs: '0.9rem', sm: '1rem' },
            lineHeight: 1.6
          }}
        >
          Need help finding the right part? Contact us for personalized recommendations and expert advice.
        </Typography>
      </Box>

      <Box sx={{ mb: 4, maxWidth: { sm: '300px' } }}>
        <FormControl fullWidth>
          <Select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            sx={{
              backgroundColor: 'white',
              '& .MuiSelect-select': {
                py: 1.5,
                fontSize: '0.875rem',
              },
              '&.MuiOutlinedInput-root': {
                borderRadius: '8px',
                '& fieldset': {
                  borderColor: theme.palette.divider,
                },
                '&:hover fieldset': {
                  borderColor: theme.palette.secondary.main,
                },
                '&.Mui-focused fieldset': {
                  borderColor: theme.palette.secondary.main,
                },
              },
            }}
          >
            <MenuItem value="all">All Products</MenuItem>
            {categories.map((category) => (
              <MenuItem 
                key={category} 
                value={category}
                sx={{
                  fontSize: '0.875rem',
                  py: 1,
                }}
              >
                {category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Fade in={!loading} timeout={500}>
        <Box
          sx={{ 
            p: { xs: 1, sm: 2 }
          }}
        >
          <Grid 
            container 
            spacing={{ xs: 2, sm: 3, md: 4 }} 
            justifyContent="flex-start"
            sx={{
              '& .MuiGrid-item': {
                display: 'flex',
              },
            }}
          >
            {filteredProducts.map((item, index) => (
              <Grid 
                item 
                xs={12} 
                sm={6} 
                md={4} 
                lg={3} 
                key={item.id}
                sx={{
                  opacity: 0,
                  animation: 'fadeIn 0.5s ease-out forwards',
                  animationDelay: `${index * 0.1}s`,
                  '@keyframes fadeIn': {
                    '0%': {
                      opacity: 0,
                      transform: 'translateY(20px)',
                    },
                    '100%': {
                      opacity: 1,
                      transform: 'translateY(0)',
                    },
                  },
                }}
              >
                <Box width="100%">
                  <Item item={item} />
                </Box>
              </Grid>
            ))}
          </Grid>

          {filteredProducts.length === 0 && (
            <Box 
              display="flex" 
              justifyContent="center" 
              alignItems="center" 
              minHeight="300px"
            >
              <Typography 
                variant="h6" 
                color="text.secondary"
                textAlign="center"
              >
                No products found in this category
              </Typography>
            </Box>
          )}
        </Box>
      </Fade>
    </Container>
  );
}

export default Products;
