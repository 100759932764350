import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import {
  Payment,
  MenuOutlined,
  SearchOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Navbar = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (path) => {
    navigate(path);
    handleClose();
  };

  return (
    <Box
      display="flex"
      textAlign="center"
      width="100%"
      height="60px"
      backgroundColor="rgba(255, 255, 255, 0.95)"
      color="black"
      position="fixed"
      top="0"
      left="0"
      zIndex="1"
    >
      <Box
        width="80%"
        margin="auto"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          onClick={() => navigate("/")}
          sx={{ 
            "&:hover": { cursor: "pointer" },
            display: "flex",
            alignItems: "center"
          }}
        >
          <img 
            src="/logo_180x.jpg" 
            alt="RoadRace1 Logo" 
            style={{ height: '40px', width: 'auto' }}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          columnGap="20px"
          zIndex="2"
        >
          <IconButton 
            sx={{ color: "black", padding: "8px" }}
            onClick={() => navigate('/search')}
          >
            <SearchOutlined sx={{ fontSize: "28px" }} />
          </IconButton>
          <IconButton 
            sx={{ color: "black", padding: "8px" }}
            onClick={() => window.open('https://billing.stripe.com/p/login/00g6rx73oeRpbZK6oo', '_blank')}
          >
            <Payment sx={{ fontSize: "28px" }} />
          </IconButton>
          <IconButton 
            sx={{ color: "black", padding: "8px" }}
            onClick={handleClick}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <MenuOutlined />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => handleNavigate('/')}>Home</MenuItem>
            <MenuItem onClick={() => handleNavigate('/products')}>Products</MenuItem>
            <MenuItem onClick={() => handleNavigate('/spring-rate-request')}>Suspension and Spring Rate Recommendation Request</MenuItem>
            <MenuItem onClick={() => handleNavigate('/about-us')}>About Us</MenuItem>
            <MenuItem onClick={() => handleNavigate('/news')}>News</MenuItem>
            <MenuItem onClick={() => handleNavigate('/reviews')}>Reviews</MenuItem>
            <MenuItem onClick={() => handleNavigate('/contact')}>Contact</MenuItem>
            <MenuItem onClick={() => window.open('https://billing.stripe.com/p/login/00g6rx73oeRpbZK6oo', '_blank')}>Customer Portal</MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default Navbar;
