import { Box, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { shades } from "../../theme";

const ReturnPolicy = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      width="80%"
      margin="80px auto"
      display="flex"
      flexDirection="column"
    >
      <Typography variant="h1" textAlign="center" marginBottom="40px">
        Return Policy
      </Typography>

      <Typography variant="body1" marginBottom="30px">
        If you are unsatisfied with one of our products for any reason, you may return it for a refund within 30-days.
      </Typography>

      <Box display="flex" flexDirection="column" gap="20px">
        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="15px">
            Custom Products
          </Typography>
          <Typography variant="body1">
            Please note that returns cannot be accepted on custom products such as Brown & Miller Racing and
            RaceFlux hose assemblies (unless defective or damaged during shipping).
          </Typography>
        </Box>

        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="15px">
            Product Condition
          </Typography>
          <Typography variant="body1">
            Products that have been used, modified, damaged, or otherwise rendered unsellable by the customer
            cannot be returned, unless they present manufacturing defects or were damaged during shipping.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="15px">
            Wearable Items
          </Typography>
          <Typography variant="body1">
            Returns cannot be accepted on wearable items such as brake pads and electronics once installed.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="15px">
            Packaging Requirements
          </Typography>
          <Typography variant="body1">
            Returned products must include original packaging and all items originally included with the product.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="15px">
            Shipping Costs
          </Typography>
          <Typography variant="body1">
            Shipping costs are non-refundable. Returned products must be shipped at the customer's expense.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="15px">
            Return Authorization
          </Typography>
          <Typography variant="body1">
            All returns must be pre-authorized. You may request a return authorization via our{" "}
            <Box
              component="span"
              onClick={() => navigate("/contact")}
              sx={{
                color: shades.secondary[500],
                cursor: 'pointer',
                textDecoration: 'underline',
                '&:hover': { color: shades.secondary[600] }
              }}
            >
              Contact page
            </Box>.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ReturnPolicy;
