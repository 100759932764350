import { Box, Typography, Button, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { shades } from "../../theme";

const AboutUs = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      width="80%"
      margin="80px auto"
      display="flex"
      flexDirection="column"
    >
      <Typography variant="h1" textAlign="center" marginBottom="40px">
        About Us
      </Typography>

      <Box display="flex" flexDirection="column" gap="30px">
        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="15px">
            Our Story
          </Typography>
          <Typography variant="body1">
            RoadRace1 was born out of our collective experience of nearly 30 years on the track, where we tirelessly
            explored countless solutions and invested substantial sums of money in pursuit of knowledge. Our ultimate
            goal is to impart our wisdom and spare you from the financial burden we endured while testing various
            products and solutions throughout the years.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="15px">
            Product Availability
          </Typography>
          <Typography variant="body1">
            No matter the predicament you find yourself in or the specific product you seek, we strongly urge you to
            reach out and get in touch with us. Just because an item isn't listed on our website doesn't mean we can't
            provide valuable insights or offer it to you at an excellent price. In fact, we have an extensive inventory of
            hundreds of products that haven't yet made their way onto our site, but we can still provide them to you at a
            remarkable value.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="15px">
            Let's Connect
          </Typography>
          <Typography variant="body1" marginBottom="20px">
            If you're seeking advice or looking to exchange ideas with fellow track enthusiasts, we are here to chat with
            you! Feel free to reach out to us through various communication channels. You can give us a call, send a text
            message, or even email us. We're eager to engage in conversations and provide you with the support and
            insights you're looking for. Don't hesitate to connect with us – we're ready to assist you!
          </Typography>
          <Button
            onClick={() => navigate("/contact")}
            sx={{
              backgroundColor: shades.secondary[500],
              color: "white",
              '&:hover': { backgroundColor: shades.secondary[600] },
              padding: "10px 40px"
            }}
          >
            Contact Us
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutUs;
