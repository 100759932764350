import { useState, useEffect } from "react";
import { useParams, useNavigate, Link as RouterLink } from "react-router-dom";
import { shades } from "../../theme";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Chip,
  Fade,
  useTheme,
  Divider,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Breadcrumbs,
  Link,
} from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import parse from 'html-react-parser';

function ProductDetails() {
  const { handle } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const navigate = useNavigate();

  // Get variant options configuration
  const getVariantOptions = (variants) => {
    if (!variants || variants.length === 0) return [];

    const firstVariant = variants[0];
    const options = [];

    // Look for option names and values
    for (let i = 1; i <= 3; i++) {
      const nameKey = `option${i}Name`;
      const valueKey = `option${i}`;

      if (firstVariant[valueKey]) {
        // Get unique values for this option
        const values = new Set();
        variants.forEach(variant => {
          if (variant[valueKey]) {
            values.add(variant[valueKey]);
          }
        });

        if (values.size > 0) {
          options.push({
            name: firstVariant[nameKey] || `Option ${i}`,
            key: valueKey,
            values: Array.from(values).sort()
          });
        }
      }
    }

    return options;
  };

  // Find matching variant based on selected options
  const findMatchingVariant = (variants, selectedOpts) => {
    return variants.find(variant => 
      Object.entries(selectedOpts).every(([key, value]) => 
        variant[key] === value
      )
    );
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        console.log("Fetching product with handle:", handle);
        const response = await fetch("/data/products.json");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log("All products:", data);
        const foundProduct = data.find((p) => p.handle === handle);
        console.log("Found product:", foundProduct);
        if (foundProduct) {
          setProduct(foundProduct);
          // Initialize with first variant
          if (foundProduct.variants && foundProduct.variants.length > 0) {
            const initialVariant = foundProduct.variants[0];
            setSelectedVariant(initialVariant);
            // Set initial options
            const initialOptions = {};
            ['option1', 'option2', 'option3'].forEach(key => {
              if (initialVariant[key]) {
                initialOptions[key] = initialVariant[key];
              }
            });
            setSelectedOptions(initialOptions);
          }
        }
        setLoading(false);
      } catch (error) {
        console.error("Error loading product:", error);
        setLoading(false);
      }
    };

    fetchProduct();
  }, [handle]);

  // Update selected variant when options change
  useEffect(() => {
    if (product?.variants) {
      const matchingVariant = findMatchingVariant(product.variants, selectedOptions);
      setSelectedVariant(matchingVariant || product.variants[0]);
    }
  }, [selectedOptions, product]);

  if (!product && !loading) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="60vh"
      >
        <Typography variant="h5" color="text.secondary">
          Product not found
        </Typography>
      </Box>
    );
  }

  return (
    <Fade in={!loading} timeout={500}>
      <Container maxWidth="xl" sx={{ mt: { xs: 8, sm: 10 }, mb: 5 }}>
        <Breadcrumbs 
          separator={<NavigateNextIcon fontSize="small" />} 
          sx={{ 
            mb: 3,
            '& .MuiBreadcrumbs-separator': {
              mx: 1
            },
            '& .MuiLink-root': {
              color: theme.palette.text.secondary,
              '&:hover': {
                color: theme.palette.secondary.main,
              }
            }
          }}
        >
          <Link 
            component={RouterLink} 
            to="/"
            underline="hover"
            sx={{ 
              display: 'flex', 
              alignItems: 'center',
              fontSize: '0.875rem'
            }}
          >
            Home
          </Link>
          <Link
            component={RouterLink}
            to="/products"
            underline="hover"
            sx={{ fontSize: '0.875rem' }}
          >
            Products
          </Link>
          <Typography 
            color="text.primary"
            sx={{ fontSize: '0.875rem' }}
          >
            {product?.title || 'Loading...'}
          </Typography>
        </Breadcrumbs>

        {product && (
          <Grid container spacing={4}>
            {/* Product Image */}
            <Grid item xs={12} md={6}>
              <Paper
                elevation={0}
                sx={{
                  width: "100%",
                  aspectRatio: "1/1",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  border: `1px solid ${theme.palette.divider}`,
                  p: { xs: 2, sm: 3, md: 4 },
                  transition: 'box-shadow 0.3s ease-in-out',
                  '&:hover': {
                    boxShadow: theme.shadows[4],
                  },
                }}
              >
                <img
                  src={product.mainImage}
                  alt={product.title}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Paper>
            </Grid>

            {/* Product Details */}
            <Grid item xs={12} md={6}>
              <Box>
                <Box sx={{ mb: 3 }}>
                  {product.productType && (
                    <Chip
                      label={product.productType}
                      size="small"
                      sx={{ 
                        mb: 2,
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        fontSize: '0.75rem',
                        height: '24px',
                        fontWeight: 'medium',
                        '& .MuiChip-label': {
                          px: 1.5,
                        },
                      }}
                    />
                  )}

                  <Typography
                    variant="h4"
                    component="h1"
                    fontWeight="bold"
                    color="text.primary"
                    sx={{ 
                      fontSize: { xs: '1.75rem', sm: '2rem', md: '2.25rem' },
                      lineHeight: 1.2,
                    }}
                  >
                    {product.title}
                  </Typography>
                </Box>

                <Box display="flex" alignItems="baseline" gap={2} mb={3}>
                  <Typography
                    variant="h5"
                    color="text.primary"
                    fontWeight="bold"
                    sx={{ fontSize: { xs: '1.5rem', sm: '1.75rem' } }}
                  >
                    ${selectedVariant ? selectedVariant.price.toFixed(2) : product.price.toFixed(2)}
                  </Typography>
                  {selectedVariant?.compareAtPrice && selectedVariant.compareAtPrice > selectedVariant.price && (
                    <Typography
                      variant="h6"
                      color="text.secondary"
                      sx={{ 
                        textDecoration: "line-through",
                        fontSize: { xs: '1.1rem', sm: '1.25rem' },
                      }}
                    >
                      ${selectedVariant.compareAtPrice.toFixed(2)}
                    </Typography>
                  )}
                </Box>

                {/* Variant Selection */}
                {product?.variants && product.variants.length > 1 && (
                  <Box mb={3}>
                    {getVariantOptions(product.variants).map((option) => (
                      <FormControl 
                        key={option.key} 
                        fullWidth 
                        sx={{ mb: 2 }}
                      >
                        <InputLabel>{option.name}</InputLabel>
                        <Select
                          value={selectedOptions[option.key] || ''}
                          label={option.name}
                          onChange={(e) => setSelectedOptions(prev => ({
                            ...prev,
                            [option.key]: e.target.value
                          }))}
                        >
                          {option.values.map((value) => (
                            <MenuItem key={value} value={value}>
                              {value.replace(/['"]/g, '')} {/* Clean up any quotes */}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ))}
                  </Box>
                )}

                {/* Variant Details */}
                <Box mb={2}>
                  {selectedVariant?.sku && (
                    <Box display="flex" alignItems="center" gap={2}>
                      <Typography 
                        variant="body2" 
                        color="text.secondary"
                      >
                        SKU: {selectedVariant.sku}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: '#2e7d32', // Material UI green[800]
                          fontWeight: 'medium',
                          backgroundColor: '#e8f5e9', // Material UI green[50]
                          px: 1,
                          py: 0.5,
                          borderRadius: '4px'
                        }}
                      >
                        In Stock
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Divider sx={{ my: 3 }} />

                {/* Purchase Button */}
                <Box mb={4}>
                  <Typography variant="h6" gutterBottom>
                    How to Purchase
                  </Typography>
                  <Typography paragraph>
                    Click below to start the purchase process. We'll help you find the perfect solution for your vehicle.
                  </Typography>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      console.log('Navigating to purchase wizard with product:', {
                        ...product,
                        price: selectedVariant ? selectedVariant.price : product.price,
                        sku: selectedVariant ? selectedVariant.sku : product.sku,
                        variants: [selectedVariant || product.variants?.[0]].filter(Boolean)
                      });
                      navigate('/purchase', { 
                        state: { 
                          product: {
                            ...product,
                            price: selectedVariant ? selectedVariant.price : product.price,
                            sku: selectedVariant ? selectedVariant.sku : product.sku,
                            variants: [selectedVariant || product.variants?.[0]].filter(Boolean)
                          }
                        } 
                      });
                    }}
                    sx={{
                      py: 1.5,
                      fontSize: "1.1rem",
                      fontWeight: "bold",
                      backgroundColor: theme.palette.secondary.main,
                      '&:hover': {
                        backgroundColor: shades.secondary[600],
                      }
                    }}
                  >
                    Start Purchase Process
                  </Button>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                    We'll guide you through the process and respond within 2-4 hours during business hours.
                  </Typography>
                </Box>

                {/* Product Description */}
                <Paper
                  elevation={0}
                  sx={{
                    p: 3,
                    backgroundColor: theme.palette.grey[50],
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: '8px',
                  }}
                >
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Product Details
                  </Typography>
                  <Box
                    sx={{ 
                      color: theme.palette.text.secondary,
                      '& p': {
                        marginBottom: 2,
                        lineHeight: 1.6,
                        '&:last-child': {
                          marginBottom: 0,
                        },
                      },
                      '& strong, & b': {
                        color: theme.palette.text.primary,
                        fontWeight: 'bold',
                      },
                      '& ul, & ol': {
                        marginBottom: 2,
                        paddingLeft: 3,
                        '&:last-child': {
                          marginBottom: 0,
                        },
                      },
                      '& li': {
                        marginBottom: 1,
                        '&:last-child': {
                          marginBottom: 0,
                        },
                      },
                    }}
                  >
                    {parse(product.description || '')}
                  </Box>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>
    </Fade>
  );
}

export default ProductDetails;
