import { Box } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { shades } from "../theme";

const Rating = ({ value }) => {
  return (
    <Box display="flex" alignItems="center">
      {[1, 2, 3, 4, 5].map((star) => (
        <Box key={star} color="#ffd700">
          {star <= value ? <StarIcon /> : <StarBorderIcon />}
        </Box>
      ))}
    </Box>
  );
};

export default Rating;
