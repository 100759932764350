import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./scenes/home/Home";
import NotFound from "./scenes/notFound/NotFound";
import Reviews from "./scenes/reviews/Reviews";
import Contact from "./scenes/contact/Contact";
import ShippingTerms from "./scenes/shipping/ShippingTerms";
import LegalNotice from "./scenes/legal/LegalNotice";
import PrivacyPolicy from "./scenes/legal/PrivacyPolicy";
import ReturnPolicy from "./scenes/legal/ReturnPolicy";
import AboutUs from "./scenes/about/AboutUs";
import News from "./scenes/news/News";
import Article from "./scenes/news/Article";
import Products from "./scenes/products/Products";
import ProductDetails from "./scenes/products/ProductDetails";
import PurchaseWizard from "./scenes/purchase/PurchaseWizard";
import SpringRateRequest from "./scenes/spring-rate/SpringRateRequest";
import Search from "./scenes/search/Search";
import Navbar from "./scenes/global/Navbar";
import Footer from "./scenes/global/Footer";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <HelmetProvider>
      <div className="app">
        <BrowserRouter basename="/">
          <Navbar />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="reviews" element={<Reviews />} />
            <Route path="contact" element={<Contact />} />
            <Route path="shipping-terms" element={<ShippingTerms />} />
            <Route path="legal-notice" element={<LegalNotice />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="return-policy" element={<ReturnPolicy />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="news" element={<News />} />
            <Route path="news/:articleId" element={<Article />} />
            <Route path="products" element={<Products />} />
            <Route path="product/:handle" element={<ProductDetails />} />
            <Route path="purchase" element={<PurchaseWizard />} />
            <Route path="spring-rate-request" element={<SpringRateRequest />} />
            <Route path="search" element={<Search />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </HelmetProvider>
  );
};

export default App;
