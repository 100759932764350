import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getEnvVar } from "../../utils/env";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Stepper,
  Step,
  StepLabel,
  MenuItem,
  Grid,
  Alert,
  useTheme,
  useMediaQuery,
  MobileStepper,
} from "@mui/material";
import { Stack, Divider } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const steps = ['Contact Information', 'Vehicle Information', 'Application Details', 'Request Type'];

// Generate years from 1979 to 2024
const years = Array.from({ length: 46 }, (_, i) => 2024 - i);

function PurchaseWizard() {
  console.log('PurchaseWizard mounted');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    year: '',
    make: '',
    model: '',
    modifications: '',
    requestType: '',
  });
  const [product, setProduct] = useState(null);

  useEffect(() => {
    console.log('Location state:', location.state);
    // Get product data from location state
    if (location.state?.product) {
      console.log('Setting product:', location.state.product);
      setProduct(location.state.product);
    } else {
      console.log('No product data, redirecting to products');
      // Redirect if no product data
      navigate('/products');
    }
  }, [location, navigate]);

  const validateStep = (step) => {
    const errors = [];
    switch (step) {
      case 0:
        if (!formData.email) errors.push('Email is required');
        if (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
          errors.push('Please enter a valid email address');
        }
        break;
      case 1:
        if (!formData.year) errors.push('Year is required');
        if (!formData.make) errors.push('Make is required');
        if (!formData.model) errors.push('Model is required');
        break;
      case 2:
        if (!formData.modifications) errors.push('Modifications details are required');
        break;
      case 3:
        if (!formData.requestType) errors.push('Request type is required');
        break;
      default:
        break;
    }
    return errors;
  };

  const handleNext = () => {
    const errors = validateStep(activeStep);
    if (errors.length > 0) {
      alert(errors.join('\n'));
      return;
    }
    console.log('Moving to next step:', activeStep + 1);
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const validateForm = () => {
    const errors = [];
    if (!formData.email) errors.push('Email is required');
    if (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.push('Please enter a valid email address');
    }
    if (!formData.year) errors.push('Year is required');
    if (!formData.make) errors.push('Make is required');
    if (!formData.model) errors.push('Model is required');
    if (!formData.modifications) errors.push('Modifications details are required');
    if (!formData.requestType) errors.push('Request type is required');
    return errors;
  };

  const handleSubmit = async () => {
    const errors = validateForm();
    if (errors.length > 0) {
      alert(errors.join('\n'));
      return;
    }

    console.log('Submitting form with data:', { product, formData });
    try {
      const response = await fetch(`${getEnvVar('REACT_APP_EMAIL_SERVICE_URL')}/api/send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          productTitle: product.title || '',
          productSku: product.variants?.[0]?.sku || product.sku || '',
          productPrice: ((product.variants?.[0]?.price || product.price) || 0).toString(),
          email: formData.email,
          phone: formData.phone,
          year: formData.year.toString(),
          make: formData.make,
          model: formData.model,
          modifications: formData.modifications,
          requestType: formData.requestType
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to send email');
      }

      console.log('Purchase request email sent');
      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting request:', error);
      alert(error.message || 'Failed to submit request. Please try again.');
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              required
              label="Email"
              type="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              sx={{ mb: 2 }}
              helperText="Required for communication about your request"
            />
            <TextField
              fullWidth
              label="Phone Number"
              type="tel"
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
              helperText="Optional - for faster communication if needed"
            />
          </Box>
        );
      case 1:
        return (
          <Box sx={{ mt: 2 }}>
            <TextField
              select
              fullWidth
              label="Year"
              value={formData.year}
              onChange={(e) => setFormData({ ...formData, year: e.target.value })}
              sx={{ mb: 2 }}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              label="Make"
              value={formData.make}
              onChange={(e) => setFormData({ ...formData, make: e.target.value })}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Model"
              value={formData.model}
              onChange={(e) => setFormData({ ...formData, model: e.target.value })}
            />
          </Box>
        );
      case 2:
        return (
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Modifications, Power Level & Key Details"
              value={formData.modifications}
              onChange={(e) => setFormData({ ...formData, modifications: e.target.value })}
              helperText="Please provide details about your vehicle's modifications, power level, and any other relevant information"
            />
          </Box>
        );
      case 3:
        return (
          <Box sx={{ mt: 2 }}>
            <TextField
              select
              fullWidth
              label="Request Type"
              value={formData.requestType}
              onChange={(e) => setFormData({ ...formData, requestType: e.target.value })}
              helperText="Please select the nature of your request"
            >
              <MenuItem value="inventory">Inventory Check</MenuItem>
              <MenuItem value="specifications">Questions about Specifications</MenuItem>
              <MenuItem value="custom">Discuss Custom Application/Solution</MenuItem>
            </TextField>
          </Box>
        );
      default:
        return 'Unknown step';
    }
  };

  if (!product) {
    return null;
  }

  if (submitted) {
    return (
      <Container maxWidth="md" sx={{ mt: { xs: 2, sm: 8 }, mb: 4, px: { xs: 2, sm: 3 } }}>
        <Alert severity="success" sx={{ mb: 4 }}>
          Your request has been successfully submitted! We will reach out within 2-4 hours during business hours.
        </Alert>
        
        <Typography variant="h5" gutterBottom sx={{ mb: 4 }}>
          What happens next?
        </Typography>

        <Stack spacing={3} sx={{ mt: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
            <CheckCircleIcon color="primary" sx={{ mt: 0.5 }} />
            <Box>
              <Typography variant="h6" gutterBottom>
                1. Request Submitted
              </Typography>
              <Typography color="text.secondary">
                Your purchase request has been received
              </Typography>
            </Box>
          </Box>
          
          <Divider />
          
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
            <CheckCircleIcon color="primary" sx={{ mt: 0.5 }} />
            <Box>
              <Typography variant="h6" gutterBottom>
                2. Availability Check
              </Typography>
              <Typography color="text.secondary">
                We'll confirm stock levels and availability
              </Typography>
            </Box>
          </Box>
          
          <Divider />
          
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
            <CheckCircleIcon color="primary" sx={{ mt: 0.5 }} />
            <Box>
              <Typography variant="h6" gutterBottom>
                3. Timeline & Quote
              </Typography>
              <Typography color="text.secondary">
                We'll provide delivery timeline and final quote
              </Typography>
            </Box>
          </Box>
          
          <Divider />
          
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
            <CheckCircleIcon color="primary" sx={{ mt: 0.5 }} />
            <Box>
              <Typography variant="h6" gutterBottom>
                4. Secure Checkout
              </Typography>
              <Typography color="text.secondary">
                You'll receive an invoice with secure payment link
              </Typography>
            </Box>
          </Box>
          
          <Divider />
          
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
            <CheckCircleIcon color="primary" sx={{ mt: 0.5 }} />
            <Box>
              <Typography variant="h6" gutterBottom>
                5. Order Tracking
              </Typography>
              <Typography color="text.secondary">
                We'll provide tracking information once shipped
              </Typography>
            </Box>
          </Box>
        </Stack>

        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Button
            variant="contained"
            onClick={() => navigate('/products')}
            sx={{ minWidth: 200 }}
          >
            Continue Shopping
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: { xs: 2, sm: 8 }, mb: 4, px: { xs: 2, sm: 3 } }}>
      <Paper elevation={0} sx={{ p: { xs: 2, sm: 4 }, border: `1px solid ${theme.palette.divider}` }}>
        {/* Product Summary */}
        <Grid container spacing={2} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={4} sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
            <img
              src={product.mainImage}
              alt={product.title}
              style={{
                width: isMobile ? '60%' : '100%',
                height: 'auto',
                objectFit: 'contain',
                maxHeight: '200px',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
              <Typography variant="h5" gutterBottom>
                {product.title}
              </Typography>
              <Typography variant="body1" color="text.secondary" gutterBottom>
                SKU: {product.variants?.[0]?.sku || 'N/A'}
              </Typography>
              <Typography variant="h6" color="primary">
                ${(product.variants?.[0]?.price || product.price).toFixed(2)}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Stepper - Responsive */}
        {isMobile ? (
          <Box sx={{ mb: 4 }}>
            <Typography variant="subtitle1" align="center" gutterBottom>
              Step {activeStep + 1}: {steps[activeStep]}
            </Typography>
            <MobileStepper
              variant="dots"
              steps={steps.length}
              position="static"
              activeStep={activeStep}
              sx={{ flexGrow: 1, bgcolor: 'transparent' }}
              nextButton={<div />}
              backButton={<div />}
            />
          </Box>
        ) : (
          <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}

        {/* Step Content */}
        {getStepContent(activeStep)}

        {/* Navigation Buttons */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
          >
            {activeStep === steps.length - 1 ? 'Submit Request' : 'Next'}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default PurchaseWizard;
