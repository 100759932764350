import { useTheme } from "@mui/material";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { shades } from "../../theme";

const Footer = () => {
  const {
    palette: { neutral },
  } = useTheme();
  const navigate = useNavigate();

  return (
    <Box mt="70px" p="40px 0" backgroundColor={neutral.light}>
      <Box
        width="80%"
        margin="auto"
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        rowGap="30px"
        columnGap="clamp(20px, 30px, 40px)"
      >
        <Box width="clamp(20%, 30%, 40%)">
          <Typography
            variant="h3"
            fontWeight="700"
            mb="30px"
            color="text.primary"
            sx={{ textTransform: 'uppercase' }}
          >
            More About Us
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "14px",
              color: shades.neutral[700],
              lineHeight: 1.6
            }}
          >
            RoadRace1 was born out of our collective experience of nearly 30 years on the track.
            If you're seeking advice or looking to exchange ideas with fellow track enthusiasts, we are here! 
            Give us a call, send a text message, or email us.
            We're eager to engage in conversations and provide you with the support and insights you're looking for.
          </Typography>
        </Box>

        <Box>
          <Typography
            variant="h3"
            fontWeight="700"
            mb="30px"
            color="text.primary"
            sx={{ textTransform: 'uppercase' }}
          >
            Further Info
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <Box
              onClick={() => navigate("/news")}
              sx={{ 
                cursor: 'pointer',
                color: shades.secondary[500],
                '&:hover': { color: shades.secondary[600] }
              }}
            >
              <Typography>News</Typography>
            </Box>
            <Box
              onClick={() => navigate("/contact")}
              sx={{ 
                cursor: 'pointer',
                color: shades.secondary[500],
                '&:hover': { color: shades.secondary[600] }
              }}
            >
              <Typography>Contact Us</Typography>
            </Box>
            <Box
              onClick={() => navigate("/reviews")}
              sx={{ 
                cursor: 'pointer',
                color: shades.secondary[500],
                '&:hover': { color: shades.secondary[600] }
              }}
            >
              <Typography>Reviews</Typography>
            </Box>
            <Box
              onClick={() => navigate("/shipping-terms")}
              sx={{ 
                cursor: 'pointer',
                color: shades.secondary[500],
                '&:hover': { color: shades.secondary[600] }
              }}
            >
              <Typography>Shipping Terms and Conditions</Typography>
            </Box>
            <Box
              onClick={() => navigate("/legal-notice")}
              sx={{ 
                cursor: 'pointer',
                color: shades.secondary[500],
                '&:hover': { color: shades.secondary[600] }
              }}
            >
              <Typography>Legal Notice</Typography>
            </Box>
            <Box
              onClick={() => navigate("/privacy-policy")}
              sx={{ 
                cursor: 'pointer',
                color: shades.secondary[500],
                '&:hover': { color: shades.secondary[600] }
              }}
            >
              <Typography>Privacy Policy</Typography>
            </Box>
            <Box
              onClick={() => navigate("/return-policy")}
              sx={{ 
                cursor: 'pointer',
                color: shades.secondary[500],
                '&:hover': { color: shades.secondary[600] }
              }}
            >
              <Typography>Return Policy</Typography>
            </Box>
            <Box
              onClick={() => navigate("/about-us")}
              sx={{ 
                cursor: 'pointer',
                color: shades.secondary[500],
                '&:hover': { color: shades.secondary[600] }
              }}
            >
              <Typography>About Us</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
