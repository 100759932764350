export const articles = {
  "champcar-midohio-win": {
    title: "RoadRace1.com Dominates with Back-to-Back D Class Wins at ChampCar Endurance Series Mid-Ohio",
    date: "October 1, 2024",
    author: "Shawn Fultz",
    description: "Our team, Switched Racing, made our debut in the ChampCar Endurance Series with back-to-back D class victories at Mid-Ohio.",
    image: "/images/news/champcar-midohio-team.jpg",
    content: `
      <p>Our team, Switched Racing, made our debut in the ChampCar Endurance Series, and it couldn't have gone any better! We took our 1995 Ford Mustang out on the track and brought home first place in the D class on both Saturday and Sunday. And while the class victories were thrilling, we also managed to finish in the top 20 overall both days!</p>

      <p>We finished both races! That's 8 hours on Saturday and 7 hours on Sunday. If you've ever raced, you know how much stress that puts on a car, regardless of the platform.</p>

      <p>None of this would've been possible without the support of our amazing team. We are incredibly proud of everyone who stepped up to help out—whether it was on the track, in the pits, or behind the scenes. Each person played a crucial role in our success.</p>

      <p>Special shoutouts go to <a href="https://www.instagram.com/roadrace1_" target="_blank" rel="noopener noreferrer" style="color: #0000FF; text-decoration: none; font-weight: bold;">@roadrace1_</a> and <a href="https://www.instagram.com/guerrilla_motorsports" target="_blank" rel="noopener noreferrer" style="color: #0000FF; text-decoration: none; font-weight: bold;">@guerrilla_motorsports</a>. Without their help, we wouldn't have been as prepared or as successful. Their guidance and expertise were invaluable, and we can't thank them enough for all their hard work.</p>

      <p>This weekend wasn't just about the wins or finishing both races. It was about teamwork, dedication, and the passion that keeps us all coming back to the track. We're already looking forward to the next race and what's to come for Switched Racing.</p>

      <p>Stay tuned, because this is just the beginning! 🏁</p>

      <h4>Race Videos</h4>

      <h6>Sunday Race Start</h6>
      <iframe width="100%" height="400" src="https://www.youtube.com/embed/UqfkqpWI1eI" title="Champcar Mid Ohio 2024 - Nick Sunday Race Start" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

      <h6>Sunday Final Stint</h6>
      <iframe width="100%" height="400" src="https://www.youtube.com/embed/cw_8avMEUEA" title="Champcar Mid Ohio 2024 - Sunday Final Stint" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

      <div style="margin-top: 40px; padding: 20px; border-top: 1px solid #ccc;">
        <h6 style="text-align: right; font-size: 24px; color: #333; margin-bottom: 8px;">Shawn Fultz</h6>
        <p style="text-align: right; font-size: 18px; color: #666; font-weight: 500;">Road Race 1</p>
      </div>
    `
  },
  "back-to-basics": {
    title: "Road Race 1: Back to the Basics",
    date: "January 2025",
    author: "Shawn & Nick",
    description: "Road Race 1 is evolving to provide more personalized service and custom solutions for our customers.",
    image: "/images/news/rr1_banner.jpg",
    content: `
      <p>As the market continues to evolve and costs rise, we've realized the importance of providing a more personal touch to help our customers find the best parts and solutions for their Mustangs. At Road Race 1, we are pivoting our approach. Our web presence is shifting away from offering a wide range of products readily available for checkout and moving towards a more personalized, tailored experience for each customer.</p>

      <h4>What This Means for You</h4>

      <p>We are still accepting and processing orders! While we transition to a new model, our full product catalog and availability are no longer accessible on the site, and online checkout is temporarily unavailable. However, we can still provide personalized quotes and offer a seamless and secure way to pay online. Once your order is placed, you'll also be able to track its progress. To get started, new and returning customers can contact us directly at <a href="mailto:info@roadrace1.com" style="color: #0000FF; text-decoration: none; font-weight: bold;">info@roadrace1.com</a>.</p>

      <p>Over the years, we've discovered that our true strength lies in creating custom solutions tailored to your needs. Rather than managing a large eCommerce site with thousands of products and the overhead costs that come with it, we're focusing on what we do best—collaborating with you to design the perfect upgrades and solutions.</p>

      <p style="color: red;">We're not going anywhere—we're just evolving! This marks a major shift in how we operate, allowing us to focus on what truly sets us apart: delivering tailored solutions and exceptional service. While we're stepping away from our traditional eCommerce model, we'll still provide the same trusted products and fast, personal service.</p>

      <p>In the coming months, we'll begin adding select products back to the site, starting with Raybestos brake pads, oil cooler kits, rotors, and more. However, managing inventory for hundreds of brands is always a challenge. Even with the most robust technology, availability often requires verification via email or phone due to supplier fluctuations throughout the year.</p>

      <p>What we promise is this: a fast, personal response to confirm the availability of any product or SKU, along with a commitment to delivering your parts on a timeline where realistic expectations can be set based on supplier availability. This way, you avoid the frustration of ordering only to face delays, refunds, or a poor experience. With this approach, we aim to ensure a smoother, more reliable process for you, every time.</p>

      <h4>Key Updates for Customers</h4>

      <h6>Contact Us</h6>
      <p>Email is the best way to reach us at <a href="mailto:info@roadrace1.com" style="color: #0000FF; text-decoration: none; font-weight: bold;">info@roadrace1.com</a>. You can also text us at <a href="tel:843-471-0353" style="color: #0000FF; text-decoration: none; font-weight: bold;">843-471-0353</a>, and we'll get back to you as soon as possible.</p>

      <h6>Phone Number Update</h6>
      <p style="color: red;">The old number (843-484-3617) is no longer in service. If you need support or have questions about a prior order, please email us.</p>

      <h6>Product Access</h6>
      <p>We still have access to all the major brands we've offered before, but you'll need to contact us directly for availability and pricing.</p>

      <h4>What's Next</h4>
      <p>We're excited about this new direction and will soon introduce some helpful features to our website. These tools will simplify the process of finding parts, building oil cooler setups, and more—making it easier to get started before reaching out to us directly.</p>

      <h4>Thank You</h4>
      <p>To every single customer who has supported us over the last five years: thank you. Your loyalty and trust mean the world to us. This transition allows us to dedicate even more attention to the Ford enthusiasts who share our passion for performance and the track.</p>

      <p>Let's get back to the basics—what we love doing best.</p>

      <div style="margin-top: 40px; padding: 20px; border-top: 1px solid #ccc;">
        <h6 style="text-align: right; font-size: 24px; color: #333; margin-bottom: 8px;">Shawn & Nick</h6>
        <p style="text-align: right; font-size: 18px; color: #666; font-weight: 500;">Owners / Racers</p>
        <p style="text-align: right; font-size: 18px; color: #666; font-weight: 500;">Road Race 1</p>
      </div>
    `
  }
};
