import { Box, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { shades } from "../../theme";

const LegalNotice = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      width="80%"
      margin="80px auto"
      display="flex"
      flexDirection="column"
    >
      <Typography variant="h1" textAlign="center" marginBottom="40px">
        Legal Notice and Terms of Sale
      </Typography>

      <Typography variant="body1" marginBottom="30px">
        By purchasing a product list for sale on RoadRace1.com, the purchaser agrees to the following terms:
      </Typography>

      <Box display="flex" flexDirection="column" gap="20px">
        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="10px">
            Racing Risk Acknowledgment
          </Typography>
          <Typography variant="body1">
            Racing is a dangerous activity and the purchaser assumes all risk and liability associated with the use of
            our products. These risks include but are not limited to damage, loss, injury or death.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="10px">
            Liability Limitation
          </Typography>
          <Typography variant="body1">
            Road Race Solutions, LLP and its owner(s) are not liable for any losses or damages of any kind incurred
            while using our products.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="10px">
            Additional Policies
          </Typography>
          <Typography variant="body1">
            The purchaser agrees to the terms in our{" "}
            <Box
              component="span"
              onClick={() => navigate("/return-policy")}
              sx={{
                color: shades.secondary[500],
                cursor: 'pointer',
                textDecoration: 'underline',
                '&:hover': { color: shades.secondary[600] }
              }}
            >
              Return Policy
            </Box>
            {" "}and our{" "}
            <Box
              component="span"
              onClick={() => navigate("/privacy-policy")}
              sx={{
                color: shades.secondary[500],
                cursor: 'pointer',
                textDecoration: 'underline',
                '&:hover': { color: shades.secondary[600] }
              }}
            >
              Privacy Policy
            </Box>.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="10px">
            Product Manual Terms
          </Typography>
          <Typography variant="body1">
            The purchaser agrees to any additional terms and conditions stated in the product's manual.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="10px">
            Pricing and Availability
          </Typography>
          <Typography variant="body1">
            Road Race 1 is dedicated to being an industry leader in both service and pricing. We're committed to
            providing top-notch products at great value. While we strive for the most accurate and current pricing,
            there may be occasional inaccuracies or typographical errors in our content. Please note that all prices,
            promotions, and product availability are subject to change without prior notice.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="10px">
            Vehicle Modification Disclaimer
          </Typography>
          <Typography variant="body1">
            The practice of modifying your vehicle with aftermarket products or parts is dangerous. Road Race 1
            understands that vehicle modification is a hobby and passion, and we do our best to provide you with the
            best options in aftermarket products. By purchasing aftermarket products from Road Race 1, you agree
            to indemnify, defend, and hold harmless Road Race 1, its officers, directors, employees, agents, licensors,
            suppliers and any third party information providers from and against all losses, expenses, damages and
            costs resulting from any accidents or damages while using aftermarket products.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LegalNotice;
