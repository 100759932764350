import { Box, Typography, Pagination } from "@mui/material";
import { shades } from "../../theme";
import Rating from "../../components/Rating";
import VerifiedIcon from '@mui/icons-material/Verified';
import { useState } from "react";

const Reviews = () => {
  const [page, setPage] = useState(1);
  const reviewsPerPage = 5;

  const reviews = [
    // Recent reviews
    {
      rating: 5,
      customerName: "Chris Stephens",
      verified: true,
      timeAgo: "6 months ago",
      title: "Great Pads!",
      product: "2000 Ford Mustang Cobra R - Brembo - Raybestos R592.17 (Front Set)",
      review: "These pads are incredible. With ST47 in front and ST43 in rear, the car brakes fantastic on track! While not considered ideal for AutoCross, these pads DO work great when cold, too. They just make some noise when doing it."
    },
    {
      rating: 5,
      customerName: "Matt Smith",
      verified: true,
      timeAgo: "6 months ago",
      title: "In stock, fair price, quick delivery!",
      product: "2000 Ford Mustang Cobra R - Brembo - Raybestos R592.17 (Front Set)",
      review: "I appreciate roadrace1 serving the Mustang road racing community! You seem to have hard to get parts (specifically Raybestos brake pads in our experience). Thanks."
    },
    {
      rating: 4,
      customerName: "Justin Gelfius",
      verified: true,
      timeAgo: "6 months ago",
      title: "Quick Response",
      product: "1994-2004 Ford Mustang Cobra - Raybestos R412.16 (Front Set)",
      review: "The packages arrived on time but with wrong PN pads. I ordered ST43 and they shipped 1 but other 3 were ST47. I messaged Shawn and early the next day he expedited the ST43 pads. That was a quick response from Shawn and great customer service. See you at Nelson Ledges!"
    },
    {
      rating: 5,
      customerName: "Chad Austin",
      verified: true,
      timeAgo: "7 months ago",
      title: "Awesome brakes!",
      product: "1994-2004 Ford Mustang Cobra - Raybestos R627.15 (Rear Set)",
      review: "The st43 paired with the st47 was the move. I reached out to the guys and the got back to me right away. Everything was in stock and shipped right out. Got them in the car and works great on track. Couldn't be happier."
    },
    {
      rating: 5,
      customerName: "Alan Schneider",
      verified: true,
      timeAgo: "7 months ago",
      title: "Remote oil filter take off",
      product: "Remote Oil Filter Take-Off Adapter Plate, M20x1.50 Filter Thread",
      review: "Take-off adapter plate worked great. Fit is spot on, no issues during installation. Although it is hard to see on a Miata, it does look good. Great product."
    },
    {
      rating: 5,
      customerName: "Timothy Nida",
      verified: true,
      timeAgo: "7 months ago",
      title: "Brake pads.",
      product: "1994-2004 Ford Mustang Cobra - Raybestos R627.15 (Rear Set)",
      review: "My go to place for my Raybestos racing brake pads. Always good communication, prices, and quick shipping. Thanks RR1!"
    },
    {
      rating: 5,
      customerName: "Chris Robins",
      verified: true,
      timeAgo: "7 months ago",
      title: "Damn son",
      product: "Alt Pulley 1.75in 3-Rib Denso",
      review: "Couple days ago i needed to get my alternator spinning fast like a trap queen in the hood. Now thanks to this pulley from RR1, my alternator is getting turnt up so quick it's giving Soulja Boy motion sickness. Boom.",
      images: [
        "https://cdn.simprosysapps.com/review-app/media/2512_30018/opt_50_img-5097jpeg-30018-1715190262-30018-1715190301.jpeg",
        "https://cdn.simprosysapps.com/review-app/media/2512_30018/opt_50_img-5097jpeg-30018-1715190269-30018-1715190304.jpeg",
        "https://cdn.simprosysapps.com/review-app/media/2512_30018/opt_50_img-5097jpeg-30018-1715190278-30018-1715190306.jpeg"
      ]
    },
    {
      rating: 5,
      customerName: "Rufus Crow",
      verified: true,
      timeAgo: "7 months ago",
      title: "Quick and simple",
      product: "2000 Ford Mustang Cobra R - Brembo - Raybestos R592.17 (Front Set)",
      review: "Easily ordered and quickly delivered."
    },
    {
      rating: 5,
      customerName: "Chad Austin",
      verified: true,
      timeAgo: "7 months ago",
      title: "Fast shipping great brakes.",
      product: "2000 Ford Mustang Cobra R - Brembo - Raybestos R592.17 (Front Set)",
      review: "Order came in super fast and they stop the car great."
    },
    {
      rating: 5,
      customerName: "Jim Ryder",
      verified: true,
      timeAgo: "1 year ago",
      title: "Raybestos ST47 pads",
      product: "Raybestos R1001.15 (Front Set) - S197 Brembo Conversion",
      review: "I am very happy with these pads! I ran Hawk DTC70 and would go through a set every weekend! The Raybestos pads work as good or better and so far i've got three weekends on them!!"
    },
    {
      rating: 5,
      customerName: "George Guhse",
      verified: true,
      timeAgo: "1 year ago",
      title: "Raybestos pads for mustang",
      product: "Raybestos R1001.15 (Front Set) - S197 Brembo Conversion",
      review: "These pads have performed well road racing. Stop great and durable. Easy on rotors."
    },
    {
      rating: 5,
      customerName: "Michael R Patrick",
      verified: true,
      timeAgo: "1 year ago",
      title: "Raybestos Top Quality",
      product: "1994-2004 Ford Mustang Cobra - Raybestos R627.15 (Rear Set)",
      review: "Off season so no track experience yet. They fit like OEM. We are expecting them to perform well just like each of your Raybestos pad recommendations."
    },
    {
      rating: 5,
      customerName: "William Sparkman",
      verified: true,
      timeAgo: "1 year ago",
      title: "1996 svt cobra mustang rear sway bar.",
      product: "Eibach 36mm Front 25mm Rear Anti-Roll Kit",
      review: "Awesome the part fit straight in. No problems."
    },
    {
      rating: 5,
      customerName: "Thor Kluge",
      verified: true,
      timeAgo: "1.5 years ago",
      title: "Ram 3500 severe duty brakes",
      product: "Hawk Super Duty Street Front Brake Pads",
      review: "I replaced everything from the frame out. Brake lines, calipers, rotors and pads. Bedded everything in. Very happy with the results. Didn't know this big thing could stop so quick and with great pedal feel. Get with roadrace1 to get in contact with people that know their product and deliver the products that fulfill your needs."
    },
    {
      rating: 5,
      customerName: "Andrew Purvis",
      verified: true,
      timeAgo: "2 years ago",
      title: "Stoptech rotors",
      product: "StopTech Power Slot 94-04 Ford Mustang Front Right Slotted Rotor",
      review: "Love them so far, much more confident with the brakes. I'm sure these rotors will last much longer than regular solid ones."
    },
    {
      rating: 5,
      customerName: "Kerry Penner",
      verified: true,
      timeAgo: "2 years ago",
      title: "Excellent Service",
      product: "Maximum Motorsports Spring Rate Recommendation Request",
      review: "Great support. Nick has been a great help offering advice on upgrading and setting up my 98 GT. Shipping of parts has been slow, but I attribute that to Maximum Motorsports, not RR1."
    },
    {
      rating: 5,
      customerName: "Douglas Schenck",
      verified: true,
      timeAgo: "2 years ago",
      title: "Oil cooler",
      product: "2005-2011 Ford Mustang 3V 4.6L 3V Oil Cooler Kit",
      review: "Excellent experience working with RR1 on my oil cooler project. Prompt shipping and terrific communication from Shawn and Nick"
    },
    {
      rating: 5,
      customerName: "Dillon Csernai",
      verified: true,
      timeAgo: "2 years ago",
      title: "Remote oil filter adapter 4.6",
      product: "Remote Oil Filter Cooler Adapter 4.6 and 5.4 Ford Modular V8",
      review: "I couldn't be any happier with the way the product arrived and also looked! Works awesome!"
    },
    {
      rating: 5,
      customerName: "James Seaton",
      verified: true,
      timeAgo: "2 years ago",
      title: "Raybestos ST-43",
      product: "1994-2004 Ford Mustang Cobra - Raybestos R412.16 Front Set",
      review: "Excellent customer service and fast shipping. Parts arrived on time and as described. Thanks RR1."
    },
    {
      rating: 5,
      customerName: "Lauren Goldade",
      verified: true,
      timeAgo: "2.5 years ago",
      title: "Raybestos R592.17",
      product: "2000 Ford Mustang Cobra R - Brembo - Raybestos R592.17 Front Set",
      review: "Wow, what a difference with the right pads!!!! Thank you Nick"
    },
    {
      rating: 5,
      customerName: "Jason Scott",
      verified: true,
      timeAgo: "2.5 years ago",
      title: "Best Racing Brake Pad. Period.",
      product: "Raybestos R1001.15 Front Set - S197 Brembo Conversion",
      review: "Great bite, good life. Easy on rotors. I get over 1000 racing miles on a set."
    },
    {
      rating: 5,
      customerName: "Scott Peterson",
      verified: true,
      timeAgo: "2.5 years ago",
      title: "Oil Cooler Purchase",
      product: "MHX-245 High Efficiency Triple Pass Oil Cooler",
      review: "Easy ordering experience and fast shipping. Item was received undamaged. Its a nice looking piece and was easy to fabricate mounts for. Will find out if it solves my cooling issues this weekend"
    },
    {
      rating: 5,
      customerName: "Matthew Studer",
      verified: true,
      timeAgo: "3 years ago",
      title: "Great value and support",
      product: "1994-2004 Ford Mustang Cobra - Raybestos R627.15 Rear Set",
      review: "Great tech support!"
    },
    {
      rating: 5,
      customerName: "Larry Martin",
      verified: true,
      timeAgo: "3 years ago",
      title: "Best service ever",
      product: "MM Stainless Brake Hoses & Hard Lines 1986-93 SN95 Calipers Rear",
      review: "Exactly as described"
    },
    {
      rating: 5,
      customerName: "Aaron Batey",
      verified: true,
      timeAgo: "3 years ago",
      title: "RoadRace1, speedy and honest",
      product: "Raybestos R1001.15 Front Set - S197 Brembo Conversion",
      review: "Great service and great product. Had my pads within 3 days and paid less than the competition. Great automated alerts for payment and tracking, very pleased. Will buy again."
    },
    {
      rating: 5,
      customerName: "John Gilbert",
      verified: true,
      timeAgo: "3 years ago",
      title: "Awesome customer service",
      product: "Raybestos R1001.15 Front Set - S197 Brembo Conversion",
      review: "The guys at RR1 are awesome in selecting the correct parts because they use their product. Communication is also top notch with shipping notifications both via text and email."
    },
    {
      rating: 5,
      customerName: "Albert Hermans",
      verified: true,
      timeAgo: "3.5 years ago",
      title: "Raybestos R1001.15",
      product: "Raybestos R1001.15 Front Set - S197 Brembo Conversion",
      review: "These pads are fantastic. The grip is what I expected and I am still learning how deep I can go into corners. They do squeal sometimes but they are race pads so thats to be expected."
    },
    {
      rating: 5,
      customerName: "Christopher Morris",
      verified: true,
      timeAgo: "3.5 years ago",
      title: "Dayum son!",
      product: "Raybestos R1001.15 Front Set - S197 Brembo Conversion",
      review: "Shawn worked with me and all i gotta say is wweet Jesus! I'm now forever doomed with having to wear a life jacket to prevent me from drowning in all that booty. Now my AIX car is pulling more G's then a hood rat bumping a Migos album in downtown Atlanta. #gthing If you want your track car to bite like Chris Browns hand after a night with Rihanna, then Road Race 1 and Raybestos race pads are what you need."
    },
    {
      rating: 5,
      customerName: "Adam Carter",
      verified: true,
      timeAgo: "3.5 years ago",
      title: "Great Product, great customer support",
      product: "1996-2004 Improved Racing Ford Mustang Cobra and Mach 1 4.6L 4V Oil Cooler Kit",
      review: "Nick and Shawn made the process very easy, I have a unique car that sees a lot of track use so they helped me pick the right options and I can't wait to see how this helps my overheating issue. This kit is absolutely top notch! Everything was included and things like heat shielding on the lines close to the motor is a very nice touch. Definitely worth the money!!!"
    },
    {
      rating: 5,
      customerName: "Peter Pelekis",
      verified: true,
      timeAgo: "3.5 years ago",
      title: "Awesome oil cooler kit",
      product: "RR1 Improved Efficiency Oil Cooler Kit 1979-1995 Ford Mustang",
      review: "These guys are great to work with Nick was really helpful getting me what I needed. The kit is great and comes with a really well built cooler. I got the upgraded cooler to support 500hp so I have some head room for later mods to the engine."
    },
    {
      rating: 5,
      customerName: "Michael H.",
      verified: true,
      timeAgo: "4 years ago",
      title: "Parts order review",
      product: "MHX-245 High Efficiency Triple Pass Oil Cooler",
      review: "Parts delivered on time as ordered and fit perfectly. Great service."
    },
    {
      rating: 5,
      customerName: "Christopher M.",
      verified: true,
      timeAgo: "4 years ago",
      title: "Oil cooler",
      product: "Oil Filter Sandwich Plate with Thermostat for Oil Coolers",
      review: "I bought this cooler adapter to keep my oil temps in check. Solid piece. Well made, and coupled with the sizes of lines recommended by Shawn and Nick my oil doesnt exceed 240, even when I'm pushing hard and its 113 degrees outside. Had a slight issue with shipping and they exceeded all of my customer service expectations. These guys set the example on running a business."
    },
    {
      rating: 5,
      customerName: "Christopher M.",
      verified: true,
      timeAgo: "4 years ago",
      title: "The guys are the best",
      product: "Raybestos R1001.15 Front Set - S197 Brembo Conversion",
      review: "Amazing pads, prompt shipping, and the best prices around. Will buy from them again!"
    },
    {
      rating: 5,
      customerName: "Joseph F.",
      verified: true,
      timeAgo: "4 years ago",
      title: "ST47",
      product: "Raybestos R1001.15 Front Set - S197 Brembo Conversion",
      review: "Literally the best brake pad ive ever used."
    },
    {
      rating: 5,
      customerName: "Michael R.",
      verified: true,
      timeAgo: "4 years ago",
      title: "ST43",
      product: "StopTech ST-40 Caliper - Raybestos R609.16 Front Set",
      review: "Great overall track pad. Have two HPDES on them and they still look brand new. Great stopping power and control."
    },
    {
      rating: 5,
      customerName: "Micheal N.",
      verified: true,
      timeAgo: "4 years ago",
      title: "ST-47 has pads were as promised",
      product: "Raybestos R1001.15 Front Set - S197 Brembo Conversion",
      review: "Great service from RR1. The ST-47 pads performed as promised and are an improvement over my previously used GLOC R-12 pads."
    },
    {
      rating: 5,
      customerName: "Chris",
      verified: true,
      timeAgo: "3 years ago",
      title: "Raybestos ST47: These brakes are nuts!",
      product: "1994-2004 Ford Mustang Cobra - Raybestos R627.15 Rear Set",
      review: "Nick is one of the greatest guys I have found in the performance Mustang world. He's been around the block and really knows his stuff. So when I found out he was suggesting these pads over some other competitors, I decided it was worth it to me to give them a shot. Nick got them out the door quick and I think I had them 2 days after ordering. And man! They work! I used these in a 95 Mustang this summer on the road course and the second half of the year in AutoCross. Dramatic improvement over the old pads. Get some from Nick! You won't regret these pads or working with him!"
    }
  ];

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const pageCount = Math.ceil(reviews.length / reviewsPerPage);
  const displayedReviews = reviews.slice(
    (page - 1) * reviewsPerPage,
    page * reviewsPerPage
  );

  const averageRating = Math.round(reviews.reduce((acc, review) => acc + review.rating, 0) / reviews.length);

  return (
    <Box width="80%" m="80px auto">
      <Box textAlign="center" mb="40px">
        <Typography
          variant="h2"
          color={shades.primary[500]}
          sx={{ textTransform: "uppercase", mb: "20px" }}
        >
          Road Race 1 Reviews
        </Typography>

        <Box display="flex" justifyContent="center" alignItems="center" gap={2} mb={2}>
          <Rating value={averageRating} />
          <Typography variant="h4" color={shades.neutral[700]}>
            {averageRating} out of 5
          </Typography>
        </Box>

        <Typography color={shades.neutral[600]}>
          Based on {reviews.length} reviews
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" gap="40px">
        {displayedReviews.map((review, index) => (
          <Box key={index} sx={{ borderBottom: '1px solid #e0e0e0', pb: 4 }}>
            <Box display="flex" alignItems="center" gap={1} mb={1}>
              <Rating value={review.rating} />
              <Typography color={shades.neutral[600]} sx={{ ml: 1 }}>
                {review.timeAgo}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" gap={1} mb={1}>
              <Typography fontWeight="600">
                {review.customerName}
              </Typography>
              {review.verified && (
                <Box display="flex" alignItems="center" gap={0.5}>
                  <VerifiedIcon sx={{ color: shades.secondary[500], fontSize: 16 }} />
                  <Typography 
                    color={shades.secondary[500]}
                    sx={{ fontSize: '0.875rem' }}
                  >
                    Verified
                  </Typography>
                </Box>
              )}
            </Box>

            <Typography
              variant="h4"
              color={shades.secondary[500]}
              sx={{ mb: 1, fontWeight: 600 }}
            >
              {review.title}
            </Typography>

            <Typography
              sx={{
                fontSize: "0.875rem",
                color: shades.neutral[600],
                mb: 2,
                fontStyle: "italic"
              }}
            >
              {review.product}
            </Typography>

            <Typography sx={{ fontSize: "14px", lineHeight: 1.6, mb: review.images ? 2 : 0 }}>
              "{review.review}"
            </Typography>

            {review.images && (
              <Box display="flex" gap={2} mt={2}>
                {review.images.map((image, idx) => (
                  <Box
                    key={idx}
                    component="img"
                    src={image}
                    alt={`Review image ${idx + 1}`}
                    sx={{
                      width: '100px',
                      height: '100px',
                      objectFit: 'cover',
                      borderRadius: '4px'
                    }}
                  />
                ))}
              </Box>
            )}
          </Box>
        ))}
      </Box>

      {pageCount > 1 && (
        <Box display="flex" justifyContent="center" mt={4}>
          <Pagination
            count={pageCount}
            page={page}
            onChange={handlePageChange}
            color="primary"
            size="large"
            sx={{
              '& .MuiPaginationItem-root': {
                color: shades.secondary[500],
                '&.Mui-selected': {
                  backgroundColor: shades.secondary[500],
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: shades.secondary[600],
                  },
                },
                '&:hover': {
                  backgroundColor: shades.secondary[100],
                },
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Reviews;
