import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Box
      width="100%"
      height="70vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="20px"
      padding="20px"
    >
      <Typography variant="h1" color="primary" textAlign="center">
        404
      </Typography>
      <Typography variant="h5" textAlign="center">
        Page Not Found
      </Typography>
      <Typography variant="body1" textAlign="center" maxWidth="600px">
        We're making significant changes to improve your experience. Please visit our home page to learn more about the updates and find what you're looking for.
      </Typography>
      <Button
        onClick={() => navigate("/")}
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
      >
        Go to Home Page
      </Button>
    </Box>
  );
};

export default NotFound;
