import { Box, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { shades } from "../../theme";

const PrivacyPolicy = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      width="80%"
      margin="80px auto"
      display="flex"
      flexDirection="column"
    >
      <Typography variant="h1" textAlign="center" marginBottom="40px">
        Privacy Policy
      </Typography>

      <Box display="flex" flexDirection="column" gap="30px">
        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="15px">
            Third-Party Data Sharing
          </Typography>
          <Typography variant="body1">
            We will not sell or distribute your personal information to any third parties except as required for the normal
            course of business. Third parties to which we may be required to divulge your personal information include
            but are not limited to shipping companies, credit card processing services, and Paypal.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="15px">
            Mobile Information Policy
          </Typography>
          <Typography variant="body1">
            No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. All the
            above categories exclude text messaging originator opt-in data and consent; this information will not be
            shared with any third parties.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="15px">
            Data Security and Account Deletion
          </Typography>
          <Typography variant="body1">
            We make every effort to store your account information safely and securely. If you would like to have your
            account deleted and your personal information removed from our database, please contact us via our{" "}
            <Box
              component="span"
              onClick={() => navigate("/contact")}
              sx={{
                color: shades.secondary[500],
                cursor: 'pointer',
                textDecoration: 'underline',
                '&:hover': { color: shades.secondary[600] }
              }}
            >
              Contact page
            </Box>.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
