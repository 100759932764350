import { useState } from "react";
import { 
  Box, 
  Typography, 
  IconButton, 
  Paper,
  MobileStepper,
  useTheme,
  useMediaQuery,
  Rating
} from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { shades } from "../../theme";

// Selected reviews from the CSV data
const reviews = [
  {
    name: "Chris",
    rating: 5,
    title: "Raybestos ST47: These brakes are nuts!",
    body: "Nick is one of the greatest guys I have found in the performance Mustang world. He's been around the block and really knows his stuff. These pads work amazingly on the road course and in AutoCross. Dramatic improvement over the old pads!"
  },
  {
    name: "Douglas S.",
    rating: 5,
    title: "Oil Cooler",
    body: "Excellent experience working with RR1 on my oil cooler project. Prompt shipping and terrific communication from Shawn and Nick"
  },
  {
    name: "Adam C.",
    rating: 5,
    title: "Great Product, great customer support",
    body: "Nick and Shawn made the process very easy, I have a unique car that sees a lot of track use so they helped me pick the right options. This kit is absolutely top notch! Everything was included and things like heat shielding on the lines close to the motor is a very nice touch. Definitely worth the money!!!"
  },
  {
    name: "Peter P.",
    rating: 5,
    title: "Awesome oil cooler kit",
    body: "These guys are great to work with. Nick was really helpful getting me what I needed. The kit is great and comes with a really well built cooler. I got the upgraded cooler to support 500hp so I have some head room for later mods to the engine."
  }
];

function ReviewCarousel() {
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const maxSteps = reviews.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '800px',
        margin: '0 auto',
        position: 'relative',
      }}
    >
      <Typography
        variant="h3"
        textAlign="center"
        marginBottom="40px"
        sx={{
          fontSize: { xs: '24px', md: '32px' },
          color: shades.secondary[500],
          fontWeight: 'bold',
        }}
      >
        What Our Customers Say
      </Typography>

      <Paper
        elevation={0}
        sx={{
          p: { xs: 3, sm: 4 },
          backgroundColor: shades.neutral[100],
          borderRadius: '8px',
          border: `1px solid ${theme.palette.divider}`,
          minHeight: { xs: '300px', sm: '250px' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Rating 
            value={reviews[activeStep].rating} 
            readOnly 
            size={isMobile ? "small" : "medium"}
            sx={{ mb: 1 }}
          />
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: { xs: '1.1rem', sm: '1.25rem' },
              fontWeight: 'bold',
              color: theme.palette.text.primary,
            }}
          >
            {reviews[activeStep].title}
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{
              fontSize: { xs: '0.9rem', sm: '1rem' },
              color: theme.palette.text.secondary,
              lineHeight: 1.6,
            }}
          >
            {reviews[activeStep].body}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: { xs: '0.8rem', sm: '0.9rem' },
              color: theme.palette.text.primary,
              fontWeight: 'bold',
            }}
          >
            - {reviews[activeStep].name}
          </Typography>
        </Box>

        <Box sx={{ 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: 'auto',
        }}>
          <IconButton 
            onClick={handleBack} 
            disabled={activeStep === 0}
            sx={{
              '&.Mui-disabled': {
                opacity: 0.3,
              },
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            sx={{ 
              flexGrow: 1,
              backgroundColor: 'transparent',
              '& .MuiMobileStepper-dot': {
                width: 8,
                height: 8,
                margin: '0 4px',
              },
              '& .MuiMobileStepper-dotActive': {
                backgroundColor: theme.palette.secondary.main,
              },
            }}
            nextButton={<div />}
            backButton={<div />}
          />
          <IconButton 
            onClick={handleNext} 
            disabled={activeStep === maxSteps - 1}
            sx={{
              '&.Mui-disabled': {
                opacity: 0.3,
              },
            }}
          >
            <KeyboardArrowRight />
          </IconButton>
        </Box>
      </Paper>
    </Box>
  );
}

export default ReviewCarousel;
