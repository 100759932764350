import { Box, Typography, IconButton, useMediaQuery } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
const heroTextureImports = Array(3).fill("/rr1_banner.jpg");

const MainCarousel = () => {
  const isNonMobile = useMediaQuery("(min-width:600px");

  return (
    <Carousel
      infiniteLoop={true}
      showThumbs={false}
      showIndicators={false}
      showStatus={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) => (
        <IconButton
          onClick={onClickHandler}
          sx={{
            position: "absolute",
            top: "50%",
            left: "0",
            color: "white",
            zIndex: "10",
          }}
        >
          <NavigateBeforeIcon sx={{ fontSize: 40 }} />
        </IconButton>
      )}
      renderArrowNext={(onClickHandler, hasPrev, label) => (
        <IconButton
          onClick={onClickHandler}
          sx={{
            position: "absolute",
            top: "50%",
            right: "0",
            color: "white",
            zIndex: "10",
          }}
        >
          <NavigateNextIcon sx={{ fontSize: 40 }} />
        </IconButton>
      )}
    >
      {heroTextureImports.map((texture, index) => (
        <Box key={`carousel-image-${index}`}>
          <img
            src={texture}
            alt={`carousel-${index}`}
            style={{
              width: "100%",
              objectFit: "cover",
              backgroundAttachment: "fixed",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: "40%",
              left: "10%",
              p: 4,
              borderRadius: 1,
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              color: "white",
              display: {
                xs: "none",
                md: "block"
              }
            }}
          >
            <Typography 
              variant="h1" 
              sx={{
                fontSize: "3rem",
              width: "100%",
              mb: 1,
              textAlign: "left",
              color: "white"
              }}
            >
              Welcome to Road Race 1
            </Typography>
            <Typography
              fontWeight="bold"
              sx={{
                fontSize: "1.25rem",
                width: "100%",
                textAlign: "left"
              }}
            >
              Racing Focused Performance Parts and Gear
            </Typography>
          </Box>
        </Box>
      ))}
    </Carousel>
  );
};

export default MainCarousel;
