import MainCarousel from "./MainCarousel";
import ReviewCarousel from "./ReviewCarousel";
import { Box, Typography, Button, useTheme } from "@mui/material";
import { shades } from "../../theme";
import { useNavigate, Link as RouterLink } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <div className="home">
      <MainCarousel />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="80%"
        margin="40px auto"
        maxWidth="1200px"
        sx={{
          backgroundColor: shades.neutral[100],
          padding: { xs: "20px", md: "40px" },
          borderRadius: "8px",
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
          textAlign: "center"
        }}
      >
        <Typography 
          variant="h3" 
          marginBottom="20px"
          sx={{
            fontSize: { xs: "24px", md: "32px" },
            fontWeight: "bold",
            color: shades.secondary[500]
          }}
        >
          Browse Our Products
        </Typography>
        <Typography 
          paragraph 
          marginBottom="30px"
          sx={{
            fontSize: { xs: "16px", md: "18px" },
            maxWidth: "800px",
            color: shades.neutral[800]
          }}
        >
          Explore our selection of Racing Brake Pads, Racing Brake Rotors, and Racing Brake Caliper Kits. 
          Quality parts designed for peak performance.
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: theme.palette.secondary.main,
            color: "white",
            fontSize: "16px",
            padding: "10px 30px",
            '&:hover': {
              backgroundColor: shades.secondary[600],
            }
          }}
          onClick={() => navigate('/products')}
        >
          View All Products
        </Button>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="80%"
        margin="40px auto"
        maxWidth="1200px"
        sx={{
          backgroundColor: shades.neutral[100],
          padding: { xs: "20px", md: "40px" },
          borderRadius: "8px",
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
          textAlign: "center"
        }}
      >
        <Typography 
          variant="h3" 
          marginBottom="20px"
          sx={{
            fontSize: { xs: "24px", md: "32px" },
            fontWeight: "bold",
            color: shades.secondary[500]
          }}
        >
          Suspension and Spring Rate Recommendation Request
        </Typography>
        <Typography 
          paragraph 
          marginBottom="30px"
          sx={{
            fontSize: { xs: "16px", md: "18px" },
            maxWidth: "800px",
            color: shades.neutral[800]
          }}
        >
          Get personalized suspension and spring rate recommendations tailored to your vehicle's specifications and performance goals. 
          Our expert team will analyze your requirements and provide a detailed recommendation to optimize your setup.
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: theme.palette.secondary.main,
            color: "white",
            fontSize: "16px",
            padding: "10px 30px",
            '&:hover': {
              backgroundColor: shades.secondary[600],
            }
          }}
          onClick={() => navigate('/spring-rate-request')}
        >
          Get Recommendation
        </Button>
      </Box>

      <Box
        width="80%"
        margin="80px auto"
        maxWidth="1200px"
      >
        <ReviewCarousel />
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="80%"
        margin="80px auto"
        maxWidth="1200px"
        sx={{
          backgroundColor: shades.neutral[100],
          padding: { xs: "20px", md: "40px" },
          borderRadius: "8px",
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
          "& .MuiTypography-paragraph": {
            fontSize: "16px",
            lineHeight: 1.8,
            color: shades.neutral[800]
          }
        }}
      >
        <Typography 
          variant="h3" 
          textAlign="center" 
          marginBottom="40px"
          sx={{
            fontSize: { xs: "24px", md: "32px" },
            color: shades.secondary[500],
            fontWeight: "bold",
            textTransform: "uppercase",
            letterSpacing: "1px"
          }}
        >
          Important Update: Back to the Basics
        </Typography>

        <Typography paragraph marginBottom="24px">
          We're evolving to provide you with a more personalized experience. Our focus is shifting towards creating custom solutions tailored to your needs, ensuring you get the perfect parts for your vehicle.
        </Typography>

        <Typography paragraph marginBottom="24px">
          <Typography component="span" color="error" display="inline" sx={{ fontSize: 'inherit', fontWeight: 'bold' }}>
            We're still here and accepting orders!
          </Typography>
          {" "}Contact us at{" "}
          <a href="mailto:info@roadrace1.com" style={{ color: shades.secondary[500], textDecoration: "none", fontWeight: "bold" }}>
            info@roadrace1.com
          </a>
          {" "}for personalized quotes and expert advice.
        </Typography>

        <Button
          component={RouterLink}
          to="/news/back-to-basics"
          variant="contained"
          sx={{
            backgroundColor: theme.palette.secondary.main,
            color: "white",
            fontSize: "16px",
            padding: "10px 30px",
            marginTop: "20px",
            '&:hover': {
              backgroundColor: shades.secondary[600],
            }
          }}
        >
          Read Full Announcement
        </Button>
      </Box>
    </div>
  );
};

export default Home;
