// Get environment variable from runtime config or fallback to process.env
export const getEnvVar = (key) => {
  // Check runtime config first
  if (window._env_ && window._env_[key]) {
    return window._env_[key];
  }
  
  // Fallback to process.env
  return process.env[key];
};
