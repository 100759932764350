import { useState, useEffect } from 'react';
import { Box, TextField, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Item from '../../components/Item';

const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch products from the JSON file
    fetch('/data/products.json')
      .then(response => response.json())
      .then(data => {
        setProducts(data);
        setFilteredProducts(data);
      })
      .catch(error => console.error('Error fetching products:', error));
  }, []);

  useEffect(() => {
    // Filter products based on search term
    const filtered = products.filter(product => 
      (product.title || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
      (product.description || '').toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [searchTerm, products]);

  return (
    <Box width="80%" margin="80px auto">
      <Typography variant="h3" textAlign="center" mt="20px" mb="20px">
        Search Products
      </Typography>
      
      <TextField
        fullWidth
        label="Search products..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: "40px" }}
      />

      <Grid container spacing={3} justifyContent={filteredProducts.length > 0 ? "flex-start" : "center"}>
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
              <Item item={product} />
            </Grid>
          ))
        ) : (
          <Typography variant="h6" color="text.secondary" mt={4}>
            No products found matching your search.
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

export default Search;
