import { Box, Button, Typography, Stack, Divider, Container } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SpringRateRequest = () => {
  return (
    <Container maxWidth="md" sx={{ mt: { xs: 2, sm: 8 }, mb: 4, px: { xs: 2, sm: 3 } }}>
      <Typography variant="h3" sx={{ mb: 4, textAlign: "center" }}>
        Suspension and Spring Rate Recommendation Request
      </Typography>
      
      <Box
        display="flex"
        flexDirection="column"
        gap={3}
        maxWidth="600px"
        margin="0 auto 40px"
      >
        <Button
          variant="contained"
          size="large"
          onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSfh5dojpJhwlPMH-flI87_-rsoGfpxhjVjVcASBRzph6rndlQ/viewform", "_blank")}
          sx={{
            padding: "20px"
          }}
        >
          SRRR Request for 79-04 Mustangs with SRA
        </Button>
        
        <Button
          variant="contained"
          size="large"
          onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSe6jW7od7Tqg954TWbAlrHY5xh6_uAjP_pKCxN2MOwtKsyaQA/viewform", "_blank")}
          sx={{
            padding: "20px"
          }}
        >
          SRRR Request for 79-04 Mustangs with IRS
        </Button>
      </Box>

      <Typography variant="h5" gutterBottom sx={{ mb: 4, textAlign: "center" }}>
        What happens next?
      </Typography>

      <Stack spacing={3}>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
          <CheckCircleIcon color="primary" sx={{ mt: 0.5 }} />
          <Box>
            <Typography variant="h6" gutterBottom>
              1. Request Submitted
            </Typography>
            <Typography color="text.secondary">
              Your suspension and spring rate recommendation request has been received
            </Typography>
          </Box>
        </Box>
        
        <Divider />
        
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
          <CheckCircleIcon color="primary" sx={{ mt: 0.5 }} />
          <Box>
            <Typography variant="h6" gutterBottom>
              2. Analyze Your Request
            </Typography>
            <Typography color="text.secondary">
              Our team will review your vehicle specifications and requirements
            </Typography>
          </Box>
        </Box>
        
        <Divider />
        
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
          <CheckCircleIcon color="primary" sx={{ mt: 0.5 }} />
          <Box>
            <Typography variant="h6" gutterBottom>
              3. Follow Up
            </Typography>
            <Typography color="text.secondary">
              We'll contact you via email, text, or phone with our recommendation and inventory levels
            </Typography>
          </Box>
        </Box>
        
        <Divider />
        
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
          <CheckCircleIcon color="primary" sx={{ mt: 0.5 }} />
          <Box>
            <Typography variant="h6" gutterBottom>
              4. Timeline & Quote
            </Typography>
            <Typography color="text.secondary">
              We'll provide delivery timeline and final quote
            </Typography>
          </Box>
        </Box>
        
        <Divider />
        
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
          <CheckCircleIcon color="primary" sx={{ mt: 0.5 }} />
          <Box>
            <Typography variant="h6" gutterBottom>
              5. Secure Checkout
            </Typography>
            <Typography color="text.secondary">
              You'll receive an invoice with secure payment link
            </Typography>
          </Box>
        </Box>

        <Divider />
        
        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
          <CheckCircleIcon color="primary" sx={{ mt: 0.5 }} />
          <Box>
            <Typography variant="h6" gutterBottom>
              6. Order Tracking
            </Typography>
            <Typography color="text.secondary">
              We'll provide tracking information once shipped
            </Typography>
          </Box>
        </Box>
      </Stack>
    </Container>
  );
};

export default SpringRateRequest;
