import { Box, Typography, Container, useTheme, Breadcrumbs, Link, Card, CardContent, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { shades } from "../../theme";

import { articles } from "./articleData";

const articlesArray = Object.entries(articles)
  .map(([id, article]) => ({
    id,
    ...article,
    excerpt: article.description,
    timestamp: new Date(article.date)
  }))
  .sort((a, b) => b.timestamp - a.timestamp);

const News = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="xl" sx={{ mt: { xs: 8, sm: 10 }, mb: 5 }}>
      <Breadcrumbs 
        separator={<NavigateNextIcon fontSize="small" />} 
        sx={{ 
          mb: 3,
          '& .MuiBreadcrumbs-separator': {
            mx: 1
          },
          '& .MuiLink-root': {
            color: theme.palette.text.secondary,
            '&:hover': {
              color: theme.palette.secondary.main,
            }
          }
        }}
      >
        <Link 
          component={RouterLink}
          to="/"
          underline="hover"
          sx={{ 
            display: 'flex', 
            alignItems: 'center',
            fontSize: '0.875rem'
          }}
        >
          Home
        </Link>
        <Typography 
          color="text.primary"
          sx={{ fontSize: '0.875rem' }}
        >
          News
        </Typography>
      </Breadcrumbs>

      <Typography 
        variant="h3" 
        textAlign="center" 
        marginBottom="40px"
        sx={{
          fontSize: { xs: "24px", md: "32px" },
          color: shades.secondary[500],
          fontWeight: "bold",
          textTransform: "uppercase",
          letterSpacing: "1px"
        }}
      >
        Latest News & Updates
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        {articlesArray.map((article) => (
          <Card 
            key={article.id}
            elevation={0}
            sx={{
              backgroundColor: shades.neutral[100],
              border: `1px solid ${theme.palette.divider}`,
              transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              }
            }}
          >
            <Box 
              sx={{ 
                display: 'flex', 
                flexDirection: { xs: 'column', sm: 'row' },
                overflow: 'hidden',
                borderRadius: '4px',
                height: '100%'
              }}
            >
              <Box
                component="img"
                src={article.image}
                alt={article.title}
                sx={{
                  width: { xs: '100%', sm: '300px' },
                  height: { xs: '200px', sm: '200px' },
                  flexShrink: 0,
                  objectFit: 'cover',
                  objectPosition: 'center',
                  backgroundColor: shades.neutral[200]
                }}
              />
              <CardContent sx={{ 
                p: { xs: 3, sm: 4 },
                flex: 1,
                '&:last-child': { pb: { xs: 3, sm: 4 } }
              }}>
              <Typography 
                variant="h5" 
                gutterBottom
                sx={{
                  fontSize: { xs: "1.25rem", sm: "1.5rem" },
                  fontWeight: "bold",
                  color: shades.secondary[500],
                }}
              >
                {article.title}
              </Typography>
              
              <Typography 
                variant="subtitle2" 
                sx={{ 
                  mb: 2,
                  color: theme.palette.text.secondary,
                  fontSize: { xs: "0.875rem", sm: "1rem" }
                }}
              >
                {article.date}
              </Typography>

              <Typography 
                paragraph 
                sx={{ 
                  color: theme.palette.text.secondary,
                  mb: 3,
                  fontSize: { xs: "0.9rem", sm: "1rem" },
                  lineHeight: 1.6
                }}
              >
                {article.excerpt}
              </Typography>

              <Button
                component={RouterLink}
                to={`/news/${article.id}`}
                variant="contained"
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  color: "white",
                  '&:hover': {
                    backgroundColor: shades.secondary[600],
                  }
                }}
              >
                Read More
              </Button>
              </CardContent>
            </Box>
          </Card>
        ))}
      </Box>
    </Container>
  );
};

export default News;
