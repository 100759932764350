import React from "react";
import { Box, Typography, Container, useTheme, Breadcrumbs, Link } from "@mui/material";
import { Link as RouterLink, useParams } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Helmet } from "react-helmet-async";
import { shades } from "../../theme";
import { articles } from "./articleData";

const getAbsoluteUrl = (path) => {
  // Use environment variable or fallback to production URL
  const baseUrl = process.env.REACT_APP_URL || 'https://roadrace1.com';
  
  // If path starts with http, it's already absolute
  if (path.startsWith('http')) {
    return path;
  }

  // Handle relative paths that might start with ./
  const cleanPath = path.replace(/^\.\//, '');
  
  // Ensure path starts with /
  const normalizedPath = cleanPath.startsWith('/') ? cleanPath : `/${cleanPath}`;
  
  // For images in the public directory, ensure they're properly referenced
  if (normalizedPath.startsWith('/images/')) {
    return `${baseUrl}${normalizedPath}`;
  }
  
  return `${baseUrl}${normalizedPath}`;
};

const Article = () => {
  const theme = useTheme();
  const { articleId } = useParams();
  const article = articles[articleId];

  if (!article) {
    return (
      <Container maxWidth="xl" sx={{ mt: { xs: 8, sm: 10 }, mb: 5 }}>
        <Helmet>
          <title>Article Not Found - Road Race 1</title>
          <meta name="description" content="The requested article could not be found." />
        </Helmet>
        <Typography variant="h4" textAlign="center">Article not found</Typography>
      </Container>
    );
  }

  const absoluteImageUrl = getAbsoluteUrl(article.image);

  return (
    <Container maxWidth="xl" sx={{ mt: { xs: 8, sm: 10 }, mb: 5 }}>
      <Breadcrumbs 
        separator={<NavigateNextIcon fontSize="small" />} 
        sx={{ 
          mb: 3,
          '& .MuiBreadcrumbs-separator': {
            mx: 1
          },
          '& .MuiLink-root': {
            color: theme.palette.text.secondary,
            '&:hover': {
              color: theme.palette.secondary.main,
            }
          }
        }}
      >
        <Link 
          component={RouterLink}
          to="/"
          underline="hover"
          sx={{ 
            display: 'flex', 
            alignItems: 'center',
            fontSize: '0.875rem'
          }}
        >
          Home
        </Link>
        <Link
          component={RouterLink}
          to="/news"
          underline="hover"
          sx={{ fontSize: '0.875rem' }}
        >
          News
        </Link>
        <Typography 
          color="text.primary"
          sx={{ fontSize: '0.875rem' }}
        >
          {article.title}
        </Typography>
      </Breadcrumbs>

      <Helmet>
        <title>{`${article.title} - Road Race 1`}</title>
        <meta name="description" content={article.description} />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={article.description} />
        <meta property="og:image" content={absoluteImageUrl} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content={getAbsoluteUrl(`/news/${articleId}`)} />
        <meta property="og:site_name" content="Road Race 1" />
        <meta property="article:publisher" content="https://www.facebook.com/roadrace1" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={article.title} />
        <meta name="twitter:description" content={article.description} />
        <meta name="twitter:image" content={absoluteImageUrl} />
        
        {/* Article specific */}
        <meta property="article:published_time" content={article.date} />
        <meta property="article:author" content={article.author} />
        
        {/* Additional mobile optimization */}
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
      </Helmet>

      <Box
        sx={{
          backgroundColor: shades.neutral[100],
          padding: { xs: "20px", md: "40px" },
          borderRadius: "8px",
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)"
        }}
      >
        <Typography 
          variant="h3" 
          textAlign="center" 
          marginBottom="10px"
          sx={{
            fontSize: { xs: "24px", md: "32px" },
            color: shades.secondary[500],
            fontWeight: "bold",
            textTransform: "uppercase",
            letterSpacing: "1px"
          }}
        >
          {article.title}
        </Typography>

        <Typography 
          variant="subtitle1" 
          textAlign="center"
          marginBottom="40px"
          sx={{
            color: theme.palette.text.secondary,
            fontSize: { xs: "0.875rem", sm: "1rem" }
          }}
        >
          {article.date}
        </Typography>

        {article.image && (
          <Box sx={{ mb: 4 }}>
            <img 
              src={article.image} 
              alt={article.title}
              style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
            />
          </Box>
        )}
        <div dangerouslySetInnerHTML={{ __html: article.content }} />
      </Box>
    </Container>
  );
};

export default Article;
