import { Box, Typography, useTheme } from "@mui/material";
import { shades } from "../../theme";

const ShippingTerms = () => {
  const theme = useTheme();

  return (
    <Box
      width="80%"
      margin="80px auto"
      display="flex"
      flexDirection="column"
    >
      <Typography variant="h1" textAlign="center" marginBottom="40px">
        Free Shipping Terms and Conditions
      </Typography>

      <Typography variant="body1" marginBottom="30px">
        Road Race 1 provides free economy shipping for orders over $99 within the continental
        lower 48 states, excluding items with specified handling fees. Some exclusions may
        apply. See below.
      </Typography>

      <Box display="flex" flexDirection="column" gap="20px">
        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="10px">
            Order Value
          </Typography>
          <Typography variant="body1">
            The offer applies to orders with a merchandise value of $99 or more before taxes, handling
            fees, and any applicable discounts.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="10px">
            Eligible Regions
          </Typography>
          <Typography variant="body1">
            Free shipping is available within the continental lower 48 states.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="10px">
            Exclusions
          </Typography>
          <Typography variant="body1">
            Raybestos Brake Pads, items with special handling requirements or items explicitly stating a
            handling fee.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="10px">
            Shipping Speed
          </Typography>
          <Typography variant="body1">
            Free shipping is an economy or standard shipping method. Expedited or faster shipping
            options may be available for an additional fee.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="10px">
            Processing Time
          </Typography>
          <Typography variant="body1">
            Orders are usually processed / confirmed within 24 hours or less. Depending on the
            order we contact you via phone or email to confirm your order.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="10px">
            Delivery Time
          </Typography>
          <Typography variant="body1">
            While delivery times may vary, the estimated delivery timeframe for orders using the free
            shipping option is typically within 1-5 business days, depending on the destination and shipping method.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h3" color={shades.secondary[500]} marginBottom="10px">
            Upgrades
          </Typography>
          <Typography variant="body1">
            Customers have the option to choose faster shipping methods from a list of available options
            during checkout for an additional fee.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ShippingTerms;
